import request from '@/utils/request';

export function GetDepartList(params) {
  return request({
    url: '/depart/data',
    method: 'get',
    params
  });
}

export function GetAreaList() {
  return request({
    url: '/depart/get_depart_town_to_add',
    method: 'get'
    // params
  });
}

export function addDepart(params) {
  return request({
    url: '/depart/add_form',
    method: 'get',
    params
  });
}

export function EditDepart(params) {
  return request({
    url: '/depart/edit_form',
    method: 'get',
    params
  });
}
export function getDepartRow(params) {
  return request({
    url: '/depart/get_depart_town_to_edit',
    method: 'get',
    params
  });
}

export function DelDepart(params) {
  return request({
    url: '/depart/del',
    method: 'get',
    params
  });
}

export function checkRepeatDepart(params) {
  return request({
    url: '/depart/check_depart_name',
    method: 'get',
    params
  });
}

export function checkRepeatUser(params) {
  return request({
    url: '/depart/check_user_name',
    method: 'get',
    params
  });
}
