<template>
  <page-header :title="title" v-model="searchParams" :breadcrumbs="pathMatch">
    <template v-slot:searchbar>
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-input
            v-model="searchParams.depart_name"
            placeholder="部门/分局名称"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
          >
            查询
          </el-button>

          <el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="handleAdd"
          >
            新增
          </el-button>
        </el-form-item>
      </el-form>
    </template>

    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      @currentChange="handleCurrentChange"
      :loading="tableLoading"
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
      <template #actions="{ scope }">
        <el-button
          type="primary"
          size="small"
          @click="actionEvent(scope.row, 'edit')"
          >编辑
        </el-button>
        <el-button
          type="danger"
          size="small"
          @click="actionEvent(scope.row, 'delete')"
          >删除
        </el-button>
      </template>
    </default-table>

    <el-dialog
      :title="(isEdit ? '修改' : '新增') + '分局'"
      :close-on-click-modal="false"
      :visible.sync="visible"
    >
      <el-form label-width="120px" :rules="rules" ref="form" :model="formData">
        <el-form-item label="分局名称" prop="depart_name">
          <el-input
            ref="depart_name"
            autocomplete="off"
            v-model="formData.depart_name"
            style="width: 300px;"
            placeholder="请输入分局名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="帐号名称" prop="user_name">
          <el-input
            ref="user_name"
            autocomplete="off"
            v-model="formData.user_name"
            placeholder="请输入帐号名称"
            style="width: 300px;"
          ></el-input>
        </el-form-item>
        <el-form-item label="管辖区域" prop="town">
          <!-- <div class="checkbox-box"> -->
          <el-checkbox-group class="checkbox-box" v-model="formData.town">
            <el-checkbox
              class="checkbox-item"
              v-for="item in areaList"
              :key="item.area_id"
              :label="item.area_id"
              name="type"
            >
              {{ item.area_name }}
            </el-checkbox>
          </el-checkbox-group>
          <!-- </div> -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button type="native-type" size="mini" @click="visible = false">
          取 消
        </el-button>
        <el-button type="primary" size="mini" @click="handleSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </page-header>
</template>

<script>
import _ from 'lodash';
import PageHeader from '@/components/PageHeader';
import DefaultTable from '@/components/DefaultTable/new';
import {
  GetDepartList,
  GetAreaList,
  addDepart,
  DelDepart,
  getDepartRow,
  checkRepeatDepart,
  checkRepeatUser
  // EditDepart
} from '@/api/depart';

export default {
  components: {
    PageHeader,
    DefaultTable
  },
  data() {
    const _this = this;
    return {
      tableLoading: true,
      searchParams: {
        depart_name: ''
      },
      loading: true,
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: true,
          width: 64,
          scopedSlots: {
            customRender: 'index'
          }
        },
        {
          title: '部门/分局名称',
          key: 'name',
          fixed: true,
          minWidth: 118
        },
        {
          title: '帐号名称',
          key: 'user_name'
        },
        {
          title: '管理区域',
          key: 'depart_town'
        },
        {
          title: '操作',
          fixed: 'right',
          key: 'actions',
          width: 180,
          extra: [
            { label: '编辑', name: 'edit' },
            { label: '删除', name: 'delete' }
          ],
          scopedSlots: {
            customRender: 'actions'
          }
        }
      ],
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      },
      isEdit: true,
      visible: false,
      areaList: [], //区域列表
      formData: {
        id: '',
        town: [],
        depart_name: '',
        user_name: ''
      },
      depart_rules: [],
      rules: {
        depart_name: [
          { required: true, message: '请输入分局名称' },
          {
            trigger: 'blur',
            validator(rule, value, callback) {
              checkRepeatDepart({
                depart_name: value,
                depart_id: _this.formData.id
              })
                .then(() => callback())
                .catch(err => callback(err));
            }
          }
        ],
        user_name: [
          { required: true, message: '请输入帐号名称' },
          {
            trigger: 'blur',
            validator(rule, value, callback) {
              checkRepeatUser({
                user_name: value,
                depart_id: _this.formData.id
              })
                .then(() => callback())
                .catch(err => callback(err));
            }
          }
        ],
        town: [{ required: true, message: '请选择管辖区域' }]
      },
      departList: [
        { id: 1, area_name: '111', is_checked: true },
        { id: 2, area_name: '222', is_checked: false },
        { id: 3, area_name: '333', is_checked: true }
      ]
    };
  },
  computed: {
    title() {
      const currentName = this.$router.history.current.name;
      return this.$locales[currentName];
    }
  },
  created() {
    this.pathMatch = this.$router.history.current.matched;

    this.handleQuery({
      current: 1,
      pageSize: 10
    });

    this.GetAreaList();
  },
  methods: {
    actionEvent(row, type) {
      switch (type) {
        case 'edit':
          this.handleEdit(row);
          break;
        case 'delete':
          this.$confirm('是否删除该条信息？', '', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              this.handleDel(row);
            })
            .catch(() => {});
          break;
      }
    },
    handleCurrentChange(current) {
      this.handleQuery({
        current,
        pageSize: 10
      });
    },
    // 修改数据回显
    showEdit(row) {
      getDepartRow({
        depart_id: row.id
      }).then(res => {
        if (res.code == 0) {
          var _town = [];
          res.data = !res.data ? this.departList : res.data;
          // res.data.forEach((item, val) => {
          this.departList.forEach(item => {
            if (item.is_checked) {
              _town.push(item.area_id);
            }
          });
          this.formData = {
            id: row.id,
            depart_name: row.name,
            town: _town,
            user_name: row.user_name
          };
          // console.log(this.formData);
          this.visible = true;
        }
      });
    },
    // 删除分局
    handleDel(row) {
      DelDepart({
        id: row.id
      }).then(res => {
        if (res.code == 0) {
          this.handleQuery({
            current: 1,
            pageSize: 10
          });
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }
      });
    },
    // 地区列表
    GetAreaList() {
      var that = this;
      GetAreaList().then(res => {
        if (res.code == 0) {
          that.areaList = res.data;
          // console.log(that.areaList);
        }
      });
    },
    // 分局列表
    handleQuery({ current, pageSize }) {
      this.pagination.current = current || 1;
      const params = {
        page: current || 1,
        limit: pageSize || 10,
        ...this.searchParams
      };
      GetDepartList(params).then(res => {
        this.data = res.data;
        if (res.code === 0) {
          this.pagination = {
            ...this.pagination,
            total: res.count
          };
        } else {
          this.pagination = {
            ...this.pagination,
            total: 0
          };
        }
        this.tableLoading = false;
      });
    },
    // 新增分局
    handleAdd() {
      this.visible = true;
      this.isEdit = false;
      this.formData = {
        id: '',
        town: [],
        depart_name: '',
        user_name: ''
      };
    },
    handleEdit(data) {
      this.isEdit = true;
      const townArr = _.split(data.depart_town, ',');
      let _town = [];
      // townArr
      this.areaList.map(item => {
        townArr.forEach(town => {
          if (item.area_name === town) {
            _town.push(item.area_id);
          }
        });
      });
      // console.log(_town);
      this.formData = {
        id: data.id,
        depart_name: data.name,
        town: _town,
        user_name: data.user_name
      };
      this.visible = true;
    },
    // 修改分局
    handleSubmit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          const params = {
            edit_id: this.formData.id,
            depart_name: this.formData.depart_name,
            town: this.formData.town.toString(),
            user_name: this.formData.user_name
          };

          addDepart(params)
            .then(res => {
              if (res.code === 0) {
                this.$message({
                  message: res.msg,
                  type: 'success'
                });
                this.visible = false;
                this.handleQuery({
                  current: 1,
                  pageSize: 10
                });
                this.form = {
                  id: '',
                  town: [],
                  depart_name: '',
                  user_name: ''
                };
              }
            })
            .catch(err => {
              this.$message.error(err);
              console.error(err);
              this.visible = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消按钮
    handleCancel(type) {
      if (type == 'edit') {
        this.editDialog = false;
      } else {
        this.addDialog = false;
      }
      this.form = {
        id: '',
        town: [],
        depart_name: '',
        user_name: ''
      };
      this.$refs['form'].resetFields();
    }
  }
};
</script>

<style lang="scss" scoped>
.checkbox-box {
  display: grid;
  grid-template-columns: repeat(auto-fill, 25%);
  grid-column-gap: 16px;
  width: 80%;
}
</style>
